import React from "react";
import styled from "styled-components";
import Layout from "../components/layout";
import SEO from "../components/seo";
import SubscribeForm from "../components/subscribeForm";
import { HeaderPlaceholder } from "../styles/ui.styles";

const PrivacyPolicy: React.FC = () => {
  return (
    <>
      <SEO title={"Privacy Policy"} description={"Privacy Policy"} />
      <Layout>
        <HeaderPlaceholder />
        <Wrapper>
          <h1>FASTRRR INTERNATIONAL</h1>
          <h1>PRIVACY POLICY</h1>
          <p>Last Updated: 1 August, 2021</p>
          <p>GENERAL PROVISIONS</p>
          <ol>
            <li>
              <p>
                The purpose of this Privacy Policy is to inform and assure our
                Users that the personal data we process during the provision of
                our services is solely for the purposes set out in this policy,
                with appropriate legal basis, and in accordance with the
                requirements of purpose limitation, data minimisation, storage
                limitation, accuracy, integrity, fairness and accountability, by
                applying solutions that are in line with the current state of
                the art and cost-effective technology, and by informing our
                Users as data subjects in advance and ensuring the enforcement
                of their rights.
              </p>
            </li>
            <li>
              <p>
                This Privacy Policy forms an integral part of FASTRRR
                International’s General Terms and Conditions, so the provisions
                pertaining to the Product, related services, and the definitions
                of terms used in this policy are defined in the General Terms
                and Conditions of Use and this Privacy Policy should be read and
                interpreted within the framework of these General Terms.
              </p>
            </li>
            <li>
              <p>
                Therefore please read this notice carefully and thoroughly
                before placing an order for a Product and/or registering on the
                Platform. By completing a purchase and/or registering on the
                Platform, you agree to accept the terms and conditions of this
                privacy policy. Therefore, if you do not agree with the
                provisions of our privacy policy, please do not use our Product.
              </p>
            </li>

            <li>
              <p>
                Our Company reserves the right to revise this Privacy Policy. We
                will notify our Users via the email address provided in their
                User Accounts 15 days prior to the changes made. The amended
                provisions shall apply from the day of its posting on our
                Website and in our App. Your continued use of the Platform or
                the Product following the posting of any changes to these Terms
                and Conditions constitutes your acceptance of those changes. 
              </p>
            </li>

            <li>
              <p>
                This privacy policy is always available – together with the
                General Terms – on our Platform. If you have any requests,
                questions or complaints, please write us at{" "}
                <a
                  href="mailto:privacy@fastrrr.com"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  privacy@fastrrr.com
                </a>
                .
              </p>
            </li>
          </ol>

          <p>DATA CONTROLLERS</p>

          <ol>
            <li>
              <p>
                The manufacturer, the distributor of the Product and in
                particular the owner, provider and operator of the Platform -
                and concerning your personal information, the data controller –
                is FASTRRR International LLC (registered in Hungary under the
                company registration number 19-09-520671; registered seat at{" "}
                <a
                  href="https://www.google.hu/maps/place/8200Veszpr%C3%A9m+Bolg%C3%A1r+Mih%C3%A1ly+utca+24.+"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  24 Bolgár Mihály Str
                </a>
                ., 8200 Veszprém, Hungary; VAT-number: HU27034712; email:{" "}
                <a
                  href="mailto:info@fastrrr.com"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  info@fastrrr.com
                </a>
                ; hereinafter: Data Controller or Company or Service Provider).
              </p>
            </li>

            <li>
              <p>
                As the data controller, we make sure and warrant that the data
                handling performed by our Company complies with this privacy
                policy and any applicable national and European Union
                legislation, in particular the General Data Protection
                Regulation of the European Union (2016/679) and the Hungarian
                Informational Self-Determination and Freedom of Information Act
                (Act CXII of 2011).
              </p>
            </li>

            <li>
              <p>
                As the data controller, we consider it especially important to
                protect the personal information of our Users and to respect
                their right to informational self-determination. Therefore, we
                handle the personal information of our Users as confidential and
                take all the necessary and appropriate security, technical and
                organizational measures that guarantee the security of their
                data.
              </p>
            </li>

            <li>
              <p>
                As the data controller, we handle personal data on our Platform
                with appropriate legal basis specified in applicable laws, rules
                and regulations and only for the purposes set out in this
                Privacy Policy. If we wish to use the personal data on our
                Platform for a purpose other than the purpose of the original
                data collection, we will ask for our User’s prior, explicit
                consent.
              </p>
            </li>

            <li>
              <p>
                Please note that our Company is unable to fully check the
                reality, authenticity and accuracy of the personal information
                and other data provided on our Platform, therefore our Company
                may not be held liable for these.
              </p>
            </li>

            <li>
              <p>
                Please also note that the Devices do not record personal data,
                the data that can be traced back to the Data Subject can only be
                recorded and stored by the Platform in accordance with the
                User&apos;s subscription package.
              </p>
            </li>
          </ol>

          <p>DATA PROCESSORS, THIRD PARTY SERVICE PROVIDERS</p>

          <ol>
            <li>
              <p>
                In order to provide the best possible service, our Company
                engages the services of third party data processors (hereinafter
                as ‘Third Party Service Providers’) for the following purposes:
              </p>
            </li>
            <TableContainer>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <p>Purpose of data processing</p>
                    </td>
                    <td>
                      <p>Name of Third Party Service Provider</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>The platform webhosting</p>
                    </td>
                    <td>
                      <p>
                        <a
                          href="https://www.hostinger.hu/adatvedelmi-iranyelvek"
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                          Hostinger.hu
                        </a>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>
                        to ensure the provision of the Company’s services and to
                        improve user experience, services provided by third
                        party businesses
                      </p>

                      <p>(weather forecasting, GPS based location services)</p>
                    </td>
                    <td>
                      <p>
                        <a
                          href="https://openweathermap.org/cookies-details"
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                          OpenWeatherMap
                        </a>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>billing</p>
                    </td>
                    <td>
                      <p>
                        <a
                          href="https://www.szamlazz.hu/adatvedelem/"
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                          Szamlazz.hu
                        </a>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>online payment for subsciptions</p>
                    </td>
                    <td>
                      <p>
                        <a
                          href="https://www.paypal.com/us/webapps/mpp/ua/privacy-full"
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                          PayPal
                        </a>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>system information, emails and newsletters</p>
                    </td>
                    <td>
                      <p>
                        <a
                          href="https://privacy.microsoft.com/hu-hu/privacystatement"
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                          Outlook
                        </a>
                        ,{" "}
                        <a
                          href="https://mailchimp.com/legal/privacy/"
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                          MailChimp
                        </a>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>delivery services</p>
                    </td>
                    <td>
                      <p>
                        <a
                          href="https://webshippy.com/adatkezelesi-tajekoztato/"
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                          Webshippy
                        </a>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>tracking the use of the Platform</p>
                    </td>
                    <td>
                      <p>see Cookie table</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </TableContainer>

            <li>
              <p>
                In certain instances, Third Party Service Providers engaged for
                services to enable or to improve the Product provided by the
                Company, may qualify as independent data controllers. In such
                instances, our Company shall not be liable for the data handling
                performed by these Third Party Service Providers, however we
                will do everything in out ability to choose Third Party Service
                Providers who meet all requirements of data protection
                regulations.
              </p>
            </li>

            <li>
              <p>
                By completing a purchase or registering on our Platform, the
                User acknowledges that they have read, understood and accepted
                the Third Party Service Providers’ general terms and conditions
                of service. If you don’t agree with the policies of these Third
                Party Service Provider(s), please do not use our Product.
              </p>
            </li>

            <li>
              <p>
                If you have any questions or complaints about any Third Party
                Service Providers indicated on our Platform, please write to us
                at
                <a
                  href="mailto:privacy@fastrrr.com"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  privacy@fastrrr.com
                </a>
                .
              </p>
            </li>
          </ol>

          <p>LEGAL BASIS</p>

          <ol>
            <li>
              <p>Our data processing is based on the following legal bases:</p>
            </li>
            <ol>
              <li>
                <p>
                  prescribed by law for a purpose based on the public interest
                  (legal obligation)
                </p>
              </li>

              <li>
                <p>
                  the data subject has consented to the processing of their
                  personal data for one or more specific purposes (voluntary
                  consent);
                </p>
              </li>
              <li>
                <p>
                  the processing is necessary for the performance of a contract
                  to which the data subject is party or in order to take steps
                  at the request of the data subject prior to the conclusion of
                  the contract (performance of contract);
                </p>
              </li>

              <li>
                <p>
                  processing is necessary for compliance with a legal obligation
                  to which the controller is subject (legal obligation of data
                  controller);
                </p>
              </li>

              <li>
                <p>
                  processing is necessary for the protection of the legitimate
                  interests of the data controller or of a third party
                  (legitimate interest).
                </p>
              </li>
            </ol>

            <li>
              <p>
                Please note that the Hungarian E-Commerce Act states that in
                order to sell the Product and to use, modify and monitor the
                performance of the services provided by the Platform, to bill
                subscription fees and to enforce any related claims, we may
                manage the personal information necessary to identify our User
                and their address. For the purpose of selling the Product and to
                invoice our subscriptions, we may also manage our Users&apos;
                personal information identifying them, their address, the time
                and date, duration and location of our service, and data
                technically essential for the provision of the Platform in
                addition to the aforementioned personal data.
              </p>
            </li>

            <li>
              <p>
                Your voluntary consent to our Company’s data processing may be
                revoked by you as the User at any time, however having revoked
                your consent you will no longer be able to enjoy the services
                provided by our Product, and in particular by our Platform.
              </p>
            </li>
            <li>
              <p>
                You may complete a purchase on our Platform if you are at least
                16 years old and have the legal capacity to enter into such a
                legal agreement.
              </p>
            </li>
            <li>
              <p>
                It is forbidden to provide the personal information of another
                natural person, and to use the Platform in their name without
                their knowledge and consent.
              </p>
            </li>
            <li>
              <p>
                If the handling, storage or transfer of a set of personal data
                provided on our Platform is required by law, a public authority
                or a court order, we will notify the User concerned directly.
              </p>
            </li>
          </ol>

          <p>
            THE PURPOSES AND DURATION OF DATA MANAGEMENT, CATEGORIES OF PERSONAL
            INFORMATION
          </p>

          <ol>
            <li>
              <p>
                We process personal information provided to us in person or on
                our Platform solely for the purposes specified below, and only
                to the extent necessary and appropriate for these purposes and
                time. We do not use personal data for purposes other than those
                specified below. In accordance with the principle of data
                minimisation, we only process personal data that is essential
                for these purposes.
              </p>
            </li>
            <TableContainer>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <p>Purpose(s)</p>
                    </td>
                    <td>
                      <p>Personal information</p>
                    </td>
                    <td>
                      <p>Duration of data processing</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>To manage and maintain Platform</p>
                    </td>
                    <td>
                      <p>technical data</p>
                    </td>
                    <td>
                      <p>
                        The log files are stored on the system for a reasonable
                        period of time to ensure the operation of the system.
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>Managing your Subscription:</p>

                      <p>Product Purchase,</p>

                      <p>Billing,</p>

                      <p>Registering on or using Platform,</p>

                      <p>Renewal</p>
                    </td>
                    <td>
                      <p>Customer’s full name</p>

                      <p>Date of birth</p>

                      <p>Billing and/or delivery address</p>

                      <p>Phone number</p>

                      <p>Email</p>

                      <p>Purchased product(s)’s name, number</p>

                      <p>subscription package</p>

                      <p>
                        Banking information, access information from financial
                        service provider
                      </p>

                      <p>GPS-coordinates (location of device)</p>
                    </td>
                    <td>
                      <p>
                        Until the termination of the user account, or until
                        necessary by law, in compliance with regulations
                        pertaining to data storage, or in the absence of such
                        regulations, 5 years.
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>
                        Additional User Account information for product
                        development and to improve user experience
                      </p>

                      <p>(e.g. Weather forecast)</p>
                    </td>
                    <td>
                      <p>Gender</p>

                      <p>Country</p>

                      <p>City</p>

                      <p>profile picture or avatar</p>

                      <p>type and name of boat</p>
                    </td>
                    <td>
                      <p>
                        Until the termination of the user account, or until
                        necessary by law, in compliance with regulations
                        pertaining to data storage, or in the absence of such
                        regulations, 5 years.
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </TableContainer>
            <li>
              <p>
                The abovementioned “technical data” are data automatically
                generated and logged in our systems when the user’s
                computer/device logs into our system (such as IP address,
                session ID). Please note that due to the nature of the Internet,
                the data that is automatically recorded is automatically logged
                by our system without your particular consent or action – just
                by using the Internet - and that the Internet cannot work
                without these automatic server-client communications. This data
                may not be linked to the user’s other personal information,
                except in cases required by law. Only our Company, as the data
                controller, has access to this data.
              </p>
            </li>

            <li>
              <p>
                We would like to inform you that our Company does not use any
                personal information for business purposes (eg newsletter,
                direct marketing, profiling) and does not transfer data to third
                party recipients except for any data processors and/or Third
                Party Service Providers engaged by our Company.
              </p>
            </li>
            <li>
              <p>
                Please also note that we are entitled, by law, to use the data
                processed in connection with the use of our Platform for
                statistical purposes. The use of the data in a statistically
                aggregated form does not include the name of the user concerned
                or any other identifiable information in any form.
              </p>
            </li>
          </ol>

          <p>MEANS OF DATA MANAGEMENT</p>

          <ol>
            <li>
              <p>
                Our Company only handles personal information of its Users for
                the purposes prescribed by this Privacy Policy, to the extent
                and in a manner necessary for their implementation, with an
                appropriate legal ground. We shall inform you of any processing
                that is not described in this Privacy Policy – and ask for your
                explicit consent - at the time of the data collection.
              </p>
            </li>

            <li>
              <p>
                Apart from some technical information (such as your email
                address), we do not verify the personal information you provide
                on our Platform. The person providing this personal information
                is solely responsible for the accuracy of the information
                provided. By registering an email address, the User shall be
                responsible for ensuring that only the User uses our service
                from the e-mail address provided. As a result, all
                responsibility for logging in to a given email address rests
                solely with the User who registered that email address.
              </p>
            </li>

            <li>
              <p>
                Only employees with an employment relationship or other legal
                relationship with the Company, as well as data processors or
                Third Party Service Providers engaged by the Company who may
                qualify as independent data controllers are entitled to access
                personal data.
              </p>
            </li>

            <li>
              <p>
                We kindly inform our Users that courts and other public
                authorities, in particular the National Data Protection and
                Freedom of Information Authority, or other bodies authorized by
                law may contact our Company to provide information, to
                communicate, to transfer or access documents. The Company shall
                provide the authorities with personal information only to the
                extent and to the extent strictly necessary to achieve the
                purpose of the request, provided that the authority has
                indicated the exact purpose and scope of the data required.
              </p>
            </li>
          </ol>

          <p>DATA SECURITY</p>

          <ol>
            <li>
              <p>
                The Company applies IT tools used for handling personal
                information on our systems in such a way that the data managed:
              </p>
            </li>
            <ol>
              <li>
                <p>is accessible to those entitled to it (availability);</p>
              </li>

              <li>
                <p>
                  is authentic and its authentication is ensured (authenticity
                  of data management);
                </p>
              </li>

              <li>
                <p>its immutability can be justified (data integrity);</p>
              </li>

              <li>
                <p>
                  is protected against unauthorized access (data
                  confidentiality).
                </p>
              </li>
            </ol>
            <li>
              <p>
                The Company shall protect the data by appropriate measures, in
                particular against unauthorized access, alteration,
                transmission, disclosure, deletion or destruction, as well as
                against accidental destruction, damage and inaccessibility due
                to changes in the technology used.
              </p>
            </li>

            <li>
              <p>
                In order to protect the electronically managed data files in our
                records, we ensure with appropriate technical solutions that the
                stored data, unless permitted by law, cannot be directly linked
                and assigned to the data subject.
              </p>
            </li>

            <li>
              <p>
                Taking into account the current state of the art technology, the
                Company shall ensure the security of data handling with
                appropriate technical, management and organizational measures
                that provide a level of protection appropriate to the risks
                associated with data management.
              </p>
            </li>

            <li>
              <p>
                During our handling of personal information, we ensure the
                following:
              </p>
            </li>

            <ol>
              <li>
                <p>
                  confidentiality: protects information so that only those who
                  have access to it can access the information;
                </p>
              </li>

              <li>
                <p>
                  integrity: protects the accuracy and completeness of the
                  information and the method of processing;
                </p>
              </li>

              <li>
                <p>
                  availability: ensures that when an authorized user needs it,
                  they can actually access the information they need and have
                  the tools to do so.
                </p>
              </li>
            </ol>

            <li>
              <p>
                The Company’s - and where possible our partners’ – IT-systems
                and networks are protected against computer-assisted fraud,
                espionage, sabotage, vandalism, fire and flood, as well as
                against computer viruses, computer intrusions and other attacks.
              </p>
            </li>

            <li>
              <p>
                The Company makes regular and ad hoc backups of the Platform -
                its database - and its entire content.
              </p>
            </li>

            <li>
              <p>
                During the automated processing of personal information, the
                Company also ensures the following:
              </p>
            </li>

            <ol>
              <li>
                <p>to prevent unauthorized data entry;</p>
              </li>

              <li>
                <p>
                  to prevent the use of automatic data-processing systems by
                  unauthorized persons using data communication equipment;
                </p>
              </li>

              <li>
                <p>
                  to verify and trace the bodies to which personal data have
                  been or may be transmitted using data communication equipment;
                </p>
              </li>

              <li>
                <p>
                  to control and trace which personal data have been input into
                  automated data-processing systems, when and by whom;
                </p>
              </li>

              <li>
                <p>
                  the resilience of installed systems in the event of a
                  breakdown; and
                </p>
              </li>

              <li>
                <p>that errors in automated processing be reported.</p>
              </li>
            </ol>

            <li>
              <p>
                We take into account the current state of the art technology
                when we define and apply data security measures. From several
                possible data management solutions, we try to choose the one
                that provides a higher level of protection of personal
                information, unless it would be a disproportionate burden on out
                Company.
              </p>
            </li>

            <li>
              <p>
                We take measures to protect the security of our data handling by
                technical, management and organizational measures that provide a
                level of protection appropriate to the risks associated with our
                data handling.
              </p>
            </li>

            <li>
              <p>
                Please note that electronic messages transmitted over the
                Internet, regardless of protocol (e-mail, web, ftp, etc.), are
                vulnerable to network threats that can lead to unfair activity
                or the disclosure or modification of information. We take every
                precaution we can to protect ourselves from such threats. We
                monitor the systems to record any security incidents and provide
                evidence of any security incidents. System monitoring also
                enables us to check the effectiveness of the precautions taken.
                However, as our Users are aware, the Internet is not known to be
                100% secure. The Company shall not be liable for any damages
                arising from unpredictable attacks that occur despite the utmost
                care.
              </p>
            </li>
          </ol>

          <p>DATA TRANSFER</p>

          <ol>
            <li>
              <p>
                The Operator does not transfer data to any third party
                recipient, except for the abovementioned data processors or
                Third Party Service Providers engaged by the Company.
              </p>
            </li>

            <li>
              <p>
                The Company, as the data controller, is entitled and obliged to
                transfer all personal information at its disposal and duly
                stored by it to the competent authorities, obliged by law or a
                final decision. The Data Controller cannot be held liable for
                such transfer of data and the consequences thereof.
              </p>
            </li>

            <li>
              <p>
                If you find any link to an external page on the Platform
                (External Link), after clicking on the External Link and leaving
                our Platform, the Company shall not be liable for any data
                handling performed on an external website outside the Platform.
                The Company does not transfer personal data to the website on
                the External Link, save for a data processor or a Third Party
                Service Provider engaged by the Company. To learn more
                information about the data handling performed by the service
                provisers of these External Link, please read their privacy
                policy posted on their own website.
              </p>
            </li>

            <li>
              <p>
                The Company shall transfer the data not indicated above only
                with the prior and informed consent of the User.
              </p>
            </li>
          </ol>

          <p>COOKIE POLICY</p>

          <ol>
            <li>
              <p>
                The Company uses cookies and other, similar technology
                (collectively: Cookies) to distinguish our Users on the
                Platform. This helps us to provide you with a good experience
                when you browse our website and also allows us to improve our
                site.
              </p>
            </li>

            <li>
              <p>
                A cookie is a package of information (file) of variable content,
                letters and numbers, sent by a web server, which is recorded on
                the User&apos;s browser or on the computer&apos;s hard drive and
                stored for a predetermined period of validity. Cookies allow us
                to retrieve certain data of our Users and Visitors and monitor
                their use of the Internet. Cookies help to track the interests,
                internet usage habits and website visit history of the User and
                the Visitor in order to optimize the User&apos;s and the
                Visitor’s experience. Because Cookies act as a kind of tag that
                allows the Platform to recognize the User returning to the site
                and their browser can also store a valid username and password
                on that site. If the User&apos;s browser returns a previously
                saved Cookie, we have the opportunity to link the current visit
                of the User concerned with the previous ones, but only with
                regard to its own content. The information sent by Cookies makes
                it easier for Internet browsers to recognize, so that Users
                receive relevant and “personalized” content. Cookies make
                browsing more convenient, including online data security needs
                and relevant / targeted advertising. With the help of Cookies,
                the Company can also create anonymous statistics about the
                habits of page visitors, so we can further personalize the look
                and content of the page.
              </p>
            </li>

            <li>
              <p>
                When the User first visits and registers on the Platform, the
                User will see a call to cookies at the bottom of the page.
                Clicking the &apos;I accept cookies&apos; button will install
                Cookies that record your preferences for Cookies. If you do not
                agree with the provisions of our cookie policy, please do not
                use our Platform.
              </p>
            </li>

            <li>
              <p>We use the following types of Cookies on the Platform:</p>
            </li>
            <ol>
              <li>
                <p>
                  Strictly necessary cookies. These are cookies that are
                  required for the operation of our website. They include, for
                  example, cookies that enable you to log into secure areas of
                  our website.
                </p>
              </li>
              <li>
                <p>
                  Analytical/performance cookies. They allow us to recognise and
                  count the number of visitors and to see how visitors move
                  around our website when they are using it. This helps us to
                  improve the way our website works, for example, by ensuring
                  that users are finding what they are looking for easily.
                </p>
              </li>
              <li>
                <p>
                  Functionality cookies. These are used to recognise you when
                  you return to our website. This enables us to personalise our
                  content for you, greet you by name and remember your
                  preferences (for example, your choice of language or region).
                </p>
              </li>
              <li>
                <p>
                  Targeting cookies. These cookies record your visit to our
                  website, the pages you have visited and the links you have
                  followed. We will use this information to make our website and
                  the advertising displayed on it more relevant to your
                  interests. We may also share this information with third
                  parties for this purpose.
                </p>
              </li>
            </ol>
            <li>
              <p>
                You can find more information about each of the Cookies used by
                our Company, their purposes and expiration date in the table
                below:
              </p>
            </li>
            <TableContainer>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <p>Cookie name</p>
                    </td>
                    <td>
                      <p>Purpose</p>
                    </td>
                    <td>
                      <p>Source</p>
                    </td>
                    <td>
                      <p>Validity</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>laravel_cookie_consent</p>
                    </td>
                    <td>
                      <p>Recording user consent data.</p>
                    </td>
                    <td>
                      <p>
                        <a
                          href="https://laravel.com/docs/5.4/session"
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                          Laravel
                        </a>
                      </p>
                    </td>
                    <td>
                      <p>Until deleted by the User.</p>
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>
                      <p>User identification.</p>
                    </td>
                    <td>
                      <p>
                        <a
                          href="https://laravel.com/docs/5.4/session"
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                          Laravel
                        </a>
                      </p>
                    </td>
                    <td>
                      <p>Until deleted by the User.</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>XSRF_TOKEN</p>
                    </td>
                    <td>
                      <p>Anti-fraud.</p>
                    </td>
                    <td>
                      <p>
                        <a
                          href="https://laravel.com/docs/5.4/session"
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                          Laravel
                        </a>
                      </p>
                    </td>
                    <td>
                      <p>minutes</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </TableContainer>
            <li>
              <p>
                Please note that we may allow third party service providers to
                place Cookies on our Users&apos; devices. The Company is not
                responsible for the Cookies used by the third party service
                providers. More information about the Cookie policies of these
                third party service providers we use is available here:
              </p>
            </li>
            <TableContainer>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <p>Purpose of Cookie</p>
                    </td>
                    <td>
                      <p>Third Party and their Cookie policy</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>Measuring Platform Utilization</p>
                    </td>
                    <td>
                      <p>
                        <a
                          href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage"
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                          Google Analytics
                        </a>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>Notices and newsletter management</p>
                    </td>
                    <td>
                      <p>
                        <a
                          href="https://mailchimp.com/legal/cookies/"
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                          MailChimp
                        </a>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>Weather forecast</p>
                    </td>
                    <td>
                      <p>
                        <a
                          href="https://openweathermap.org/cookies-details"
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                          OpenWeatherMap
                        </a>
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </TableContainer>
            <li>
              <p>
                The User may use the browser program to set or disable certain
                cookies or all cookies. Cookies can usually be managed in the
                browsers&apos; Tools / Settings menu under Privacy / History /
                Custom Settings under the name cookie or trace.
              </p>
            </li>

            <li>
              <p>
                Please note that if you choose to disable all cookies through
                your browser - including strictly necessary cookies - you may
                not be able to access the Platform or certain parts of it.
              </p>
            </li>
          </ol>

          <p>THE RIGHTS OF DATA SUBJECTS (USERS)</p>

          <ol>
            <li>
              <p>
                The Data Controller receives questions, requests and complaints
                related to our cookie management at the email address
                <a
                  href="mailto:privacy@fastrrr.com"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  privacy@fastrrr.com
                </a>
                .
              </p>
            </li>

            <li>
              <p>
                Your query per email will only be considered authentic, if you
                send it to us via the email address you registered on our
                Platform, unless you credibly identify yourself in another way.
                We will process your query within maximum one month from the
                submission.
              </p>
            </li>

            <li>
              <p>
                The User may request information on the handling of the User’s
                own personal information, as well as request the correction of
                the User’s personal data, or – except for mandatory data
                processing – request to delete, revoke their consent, exercise
                their right to data portability and objection.
              </p>
            </li>

            <li>
              <p>
                Changes in personal data or requests for deletion of personal
                data should be submitted to us via the User’s registered e-mail
                address. The data subject may request the correction of
                inaccurate personal information and to complete incomplete
                personal information. If the personal information in our system
                is incorrect and the correct data is available to our Company,
                we will correct the personal data. Otherwise, you may change
                your personal information on your User’s Account.
              </p>
            </li>

            <li>
              <p>
                As the User, you are entitled to receive information from the
                data controller as to whether the processing of your personal
                information is in progress. If such data processing is in
                progress, you are entitled to access personal data as well as
                the following information: the purposes of data processing; the
                categories of personal data concerned; the recipients or
                categories of recipients to whom the personal data have been or
                will be communicated, including in particular third country
                recipients or international organizations; the intended duration
                of the storage of personal data; the right to rectify, erase or
                restrict data processing and to protest; the right to lodge a
                complaint with the supervisory authority; information on data
                sources; whether the Company uses automated decision-making,
                including profiling, and comprehensible information on the logic
                used and the significance of such data management and the
                expected consequences for the data subject. In the case of
                transfers of personal data to a third country or to an
                international organization, the data subject shall be entitled
                to be informed of the appropriate guarantees regarding the
                transfer.
              </p>
            </li>

            <li>
              <p>
                The Company shall make a copy of the personal information
                processed by the Company on the data subject concerned.
                Additional copies requested by the data subject may be subject
                to a reasonable fee based on our administrative costs. At the
                request of the data subject, the information will be provided in
                electronic form.
              </p>
            </li>

            <li>
              <p>
                As the data subject, you shall have the right to have your
                personal information deleted without undue delay upon your
                request for one of the following reasons:
              </p>
            </li>

            <ol>
              <li>
                <p>
                  your personal data are no longer required for the purpose for
                  which they were collected or otherwise processed;
                </p>
              </li>

              <li>
                <p>
                  you withdraw your consent on which the processing is based and
                  there is no other legal ground for the processing;
                </p>
              </li>

              <li>
                <p>
                  you object to the processing and there is no overriding
                  legitimate reason for the processing;
                </p>
              </li>

              <li>
                <p>your personal information was processed unlawfully;</p>
              </li>

              <li>
                <p>
                  your personal data must be deleted in order to fulfill a legal
                  obligation under EU law or domestic law applicable to the
                  controller;
                </p>
              </li>

              <li>
                <p>
                  your personal information was collected in connection with the
                  provision of information society services.
                </p>
              </li>
            </ol>

            <li>
              <p>
                Please note that once the request for deletion or modification
                of personal data has been fulfilled, the previous (deleted) data
                can no longer be recovered. Please note that the deletion of
                data may not be initiated if the data processing is necessary
                for one of the following reasons: fulfillment of the obligation
                under EU or domestic law applicable to the data controller, or
                to submit, enforce or protect the Company’s legal claims so
                require.
              </p>
            </li>

            <li>
              <p>
                At the request of the data subject, we restrict the data
                processing if one of the following conditions is met:
              </p>
            </li>

            <ol>
              <li>
                <p>
                  the data subject disputes the accuracy of the personal data,
                  in which case the restriction shall apply for a period which
                  allows the accuracy of the personal data to be verified;
                </p>
              </li>

              <li>
                <p>
                  the processing is unlawful and the data subject opposes the
                  deletion of the data and instead requests that their use be
                  restricted;
                </p>
              </li>

              <li>
                <p>
                  the Company no longer needs the personal data for the purposes
                  of the processing, but they are required by the data subject
                  for the establishment, exercise or defence of legal claims;
                </p>
              </li>

              <li>
                <p>
                  the data subject has objected to the processing; in this case,
                  the restriction shall apply for the period until it is
                  determined whether the legitimate reasons of the Company take
                  precedence over the legitimate reasons of the data subject.
                </p>
              </li>
            </ol>

            <li>
              <p>
                If the processing is subject to a restriction, personal data may
                be processed, with the exception of storage, only with the
                consent of the data subject or for the purpose of making,
                enforcing or protecting legal claims or protecting the rights of
                another natural or legal person. The Company shall inform the
                data subject in advance of the lifting of processing
                restriction.
              </p>
            </li>

            <li>
              <p>
                As the data subject, you have the right to request and receive
                all your personal information provided to us, in a structured,
                widely used, machine-readable format and to transmit this data
                to another data controller (data portability).
              </p>
            </li>

            <li>
              <p>
                As the data subject, you shall have the right to object at any
                time, on grounds related to your particular situation, to the
                processing of your personal data, including profiling. If and
                when the User objects, personal data may no longer be processed
                unless justified by compelling legitimate reasons of the Company
                which take precedence over the interests, rights and freedoms of
                the data subject or which relate to the submission, enforcement
                or protection of legal claims. Where personal data are processed
                for the purposes of direct marketing, as the data subject, you
                shall have the right to object at any time to the processing of
                personal data concerning you for that purpose, including
                profiling, in so far as it relates to direct marketing. If and
                when the User objects to the processing of personal data for
                direct marketing purposes, the data in question may not be
                processed for this purpose.
              </p>
            </li>

            <li>
              <p>
                As the data subject, you have the right not to be subject to a
                decision based solely on automated data processing, including
                profiling, which would produce legal effects concerning you or
                you would be significantly affected. This does not apply if the
                decision is:
              </p>
            </li>

            <ol>
              <li>
                <p>
                  necessary for the conclusion or performance of the contract
                  between the data subject and the controller;
                </p>
              </li>

              <li>
                <p>
                  authorised by EU law or domestic law applicable to the
                  controller, which also lays down appropriate measures to
                  protect the rights and freedoms and legitimate interests of
                  the data subject;
                </p>
              </li>

              <li>
                <p>based on the express consent of the data subject.</p>
              </li>
            </ol>

            <li>
              <p>
                As the data subject, you have the right to withdraw your consent
                at any time. Withdrawal of consent shall not affect the
                lawfulness of the data processing prior to withdrawal.
              </p>
            </li>

            <li>
              <p>
                The Company shall, without undue delay, but in any case within
                one month of receipt of the request, inform the data subject of
                the action taken on the request. If necessary, taking into
                account the complexity of the application and the number of
                applications, this time limit may be extended by a further two
                months. The person concerned shall be informed of the extension
                of the time limit, stating the reasons for the delay, within one
                month of receipt of the request. If the data subject has
                submitted the request electronically, the information shall be
                provided electronically, unless the data subject requests
                otherwise. If the Company fails to start processing the data
                subject&apos;s request, it shall inform the data subject without
                delay, but no later than one month from the receipt of the
                request, and shall inform them of the reasons and of the
                possibility of complaint to a supervisory authority.
              </p>
            </li>

            <li>
              <p>
                The Company shall provide the requested information and
                information free of charge. If the data subject&apos;s request
                is clearly unfounded or, in particular due to its repetitive
                nature, excessive, the Company may charge a reasonable fee or
                refuse to act on the request, taking into account the
                administrative costs of providing the requested information or
                the requested action to be taken.
              </p>
            </li>

            <li>
              <p>
                We will inform all recipients to whom the personal information
                have been communicated, of any rectification, erasure or
                restriction on the processing of data, unless this proves
                impossible or requires a disproportionate effort. At the
                explicit request of the data subject, the Company shall inform
                these recipients.
              </p>
            </li>

            <li>
              <p>
                Any person who has suffered damage as a result of a breach of
                data protection provisions is entitled to compensation for the
                damage suffered from the Company as the data controller. The
                data processor shall be liable for damages caused by the data
                processing only if it has not complied with the obligations
                specified in the law, which are specifically imposed on the data
                processors, or if it has disregarded or acted contrary to the
                data controller&apos;s lawful instructions. Where several
                controllers or processors or both controllers and processors are
                involved in the same processing and are liable for damages
                caused by the processing, each controller or processor shall be
                jointly and severally liable for the total damage. The Company,
                as the data controller, is released from liability if it proves
                that it is not liable in any way for the event that caused the
                damage.
              </p>
            </li>
          </ol>

          <p>DISPUTE SETTLEMENT</p>

          <ol>
            <li>
              <p>
                In the event of a breach of their rights, the data subject may,
                as a general rule, apply to the court of the Company’s
                registered seat against the data controller.
              </p>
            </li>

            <li>
              <p>
                Complaints can be lodged with the National Data Protection and
                Freedom of Information Authority:
              </p>
            </li>

            <p>
              National Data Protection and Freedom of Information Authority
              <br />
              H-1125 Budapest, Szilágyi Erzsébet fasor 22/C.
            </p>

            <p>
              Mailing address: PO BOX 5, 1530 Budapest, Hungary
              <br />
              Phone: 06-1-391-1400 Fax: 06-1-391-1410
              <br />
              E-mail:{" "}
              <a
                href="mailto:ugyfelszolgalat@naih.hu"
                target="_blank"
                rel="noreferrer noopener"
              >
                ugyfelszolgalat@naih.hu
              </a>
              <br />
              Website:{" "}
              <a
                href="http://www.naih.hu/"
                target="_blank"
                rel="noreferrer noopener"
              >
                http://www.naih.hu
              </a>
            </p>

            <li>
              <p>
                If you have any questions or comments, please contact us at the
                following contact details:{" "}
                <a
                  href="mailto:privacy@fastrrr.com"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  privacy@fastrrr.com
                </a>
              </p>
            </li>
          </ol>
        </Wrapper>
        <SubscribeForm />
      </Layout>
    </>
  );
};

export default PrivacyPolicy;

const Wrapper = styled.div`
  max-width: 1600px;
  margin: 0 auto;
  padding: 0 var(--gutter);
  padding-bottom: 100px;
  ol ol li {
    list-style-type: lower-latin;
  }
`;

const TableContainer = styled.div`
  /* This will allow a table to scroll horizontally on smaller screens. */
  overflow-x: auto;
  table {
    min-width: 100%;
  }
  tr:nth-child(even) {
    background-color: #dedede;
  }
  td {
    padding: 1rem;
    min-width: 200px;
    p {
      margin: 0;
    }
  }
`;
